/** @format */

import React from "react";
import { BsArrowUpRight } from "react-icons/bs";

const Marketing = () => {
  return (
    <>
      <div className='marketing_sec container-fluid'>
        <div className=''>
          <div className='container'>
            <div className='top_text'>
              <h3>The Power of Community</h3>
              <p className='w-75 w-sm-100'>
                HOC is rooted in the principle of community-driven investment.
                We're committed to empowering our users, allowing them to unite
                and collectively influence the real estate industry.
              </p>
              <p className='w-75 w-sm-100'>
                {" "}
                This collaborative approach is designed to benefit all members
                and redefine the way we invest in properties.
              </p>
            </div>
            <div className='row'>
              <div className='col-md-7'>
                <img src='assets/images/m1f.png' className='img-fluid' alt='' />
              </div>
              <div className='col-md-5'>
                <div className='right_text'>
                  <h3>Why Blockchain?</h3>
                  <p>
                    Blockchain technology serves as the cornerstone of our
                    mission. It equips us with the tools needed to provide
                    transparency, security, and efficiency like never before.
                  </p>
                  <p>
                    Through blockchain, we're breaking down age-old barriers,
                    enabling individuals to participate in real estate
                    investment with a newfound sense of confidence and
                    accessibility.
                  </p>
                  <div className=' mt-5'>
                    <a
                      href='https://dex.myhoc.io/'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {" "}
                      <button className='btn-style '>
                        Launch App &nbsp;
                        <BsArrowUpRight />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketing;
