/** @format */

import React from "react";


const Footer = () => {
  return (
    <footer>
      <div className='footer container-fluid '>
        <div className='row'>
          <div className='col-sm-4 m-auto text-start'>
            <p>Home Owner's Club</p>
          </div>
          <div className='link_footer col-sm-4  m-auto text-center'>
            <a
              href='https://twitter.com/Home_OwnersClub'
              target='_blank'
              rel='noopener noreferrer'>
              Twitter
            </a>
            <a
              href='https://t.me/Home_OwnersClub'
              target='_blank'
              rel='noopener noreferrer'>
              Telegram
            </a>
            <a
              href='http://discord.gg/sxTZuv8z'
              target='_blank'
              rel='noopener noreferrer'>
              Discord
            </a>
          </div>
          <div className='col-sm-4  m-auto text-end'>
            <span>2023 - All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
