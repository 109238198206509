/** @format */

import React from "react";

const data = [
  {
    id: 1,
    image: "assets/images/cr1.png",
    head: "Join the Club",

    video:
      "Getting started with Home Owners Club (HOC) is easy and free. Become a member of our ever-expanding community by signing up. As a member, you'll gain access to a world of real estate investment opportunities previously reserved for a select few.",
  },
  {
    id: 2,
    image: "assets/images/cr2.png",
    head: "Explore Properties",
    video:
      "Once you're a part of the HOC community, you can explore a diverse portfolio of investment opportunities. Browse through a wide range of properties, each offering its unique potential for growth and profitability. Whether you're interested in residential, commercial, or other real estate ventures, our platform has something for everyone.",
  },
  {
    id: 3,
    image: "assets/images/cr3.png",
    head: "Earn Together",
    video:
      "The beauty of HOC lies in collective ownership. As property values grow over time, you'll share in the profits alongside your fellow investors. This collaborative approach ensures that everyone benefits as the real estate market evolves. It's a win-win scenario, where your financial well-being is tied to the success of the entire community.",
  },
];

const Cards = () => {
  return (
    <>
      <div className='card_sec mt-3'>
        <div className='container'>
          <p>How It Works</p>
          <h3>Democratizing Real Estate Investment</h3>

          {/* <p className="bottom_text">
            Instead, we're going to leverage on my #1 secret (that took me years
            to figure out), and <br /> we're going to create videos for the 3
            PHASES of TRAFFIC :
          </p> */}
          <div className='row mt-5'>
            {data.map(res => {
              return (
                <div className='col-md-4' key={res.id}>
                  <div className='card'>
                    <div className='head'>
                      <h2>{res.head}</h2>
                    </div>
                    <div className='card_img'>
                      <img src={res.image} className='img-fluid' alt='' />
                    </div>

                    <div className='para'>
                      <p className='mx-2'>{res.video}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='text'>
            <p className='botm_txt'>
              <span>A New Era in Real Estate Investment</span>{" "}
            </p>
            <p className='botm_txt'>
              Home Owners Club is ushering in a new era in real estate
              investment.
            </p>
            <p className='botm_txt'>
              We believe in the power of the collective, and our platform
              reflects that belief. Join us in reshaping the way we invest in
              properties, making it an inclusive and rewarding experience for
              all.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
