/** @format */

import React from "react";
import {
  FaTwitter,
  FaTelegram,
  FaYoutube,
  FaDiscord,
  FaMedium,
} from "react-icons/fa";
const Challenge = () => {
  return (
    <>
      <div className='challenge_sec'>
        <div className='container'>
          <div className='top_text'>
            <h3>
              Join Us on this <span> Transformative Journey</span>
            </h3>
          </div>
          <div className='row mt-5'>
            <div className='col-md-6 m-auto'>
              <div className='left_text'>
                {/* <h3>Here's the TRUTH:</h3> */}
                <p>
                  We invite you to embark on this transformative journey with
                  us.
                </p>
                <p>
                  Join the ranks of those who are helping democratize real
                  estate investment, turning it into an inclusive endeavor that
                  empowers individuals to invest, own, and actively shape the
                  future of the property market.
                </p>
                <h3>Discover HOC</h3>

                <p>
                  Come and explore the world of HOC, where blockchain technology
                  converges with real estate investment to create a more
                  equitable and accessible future.
                </p>
                <p>
                  Discover how you can be a part of this revolutionary movement
                  today.
                </p>
                <div>
                  <a
                    href='https://twitter.com/Home_OwnersClub'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <FaTwitter className='text-warning fs-3 mx-3' />
                  </a>
                  <a
                    href='https://t.me/Home_OwnersClub'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <FaTelegram className='text-warning fs-3 mx-3' />
                  </a>
                  <a
                    href='http://discord.gg/sxTZuv8z'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <FaDiscord className='text-warning fs-3 mx-3' />
                  </a>
                  <a
                    href='https://medium.com/@homeownersclub'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <FaMedium className='text-warning fs-3 mx-3' />
                  </a>
                  <a
                    href='https://www.youtube.com/@Homeownerclub'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <FaYoutube className='text-warning fs-3 mx-3' />
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <img src='assets/images/c1g.png' className='img-fluid' alt='' />
            </div>
          </div>
          <div className='text'></div>
          <div className='bottom_text'>
            <div className='d-flex justify-content-between flex-wrap align-items-center'>
              <h3>Why Real Estate?</h3>

              <p className='mt-3'>
                Real estate has been a cornerstone of wealth creation for
                centuries. It's a tangible asset that provides stability and
                growth potential. With HOC, you can access the world of real
                estate without the traditional barriers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Challenge;
