/** @format */

import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { FaTelegram } from "react-icons/fa";

const Work = () => {
  return (
    <>
      <div className='work_sec pt-5'>
        <div className='container'>
          <div className='d-flex justify-content-center top_img'>
            {/* <img src='assets/images/w1.png' className='img-fluid' alt='' /> */}
            <h3>Join Our Community Today</h3>
          </div>
          <div className='d-flex justify-content-center my-4'>
            <div className='text'>
              <p>
                Are you prepared to embark on this exhilarating venture? Join
                the <span>Home Owners Club</span> today and redefine your
                approach to real estate investment.
              </p>
            </div>
          </div>
          <div className='hero_btn'>
            <a
              href='https://t.me/Home_OwnersClub'
              target='_blank'
              rel='noopener noreferrer'>
          
                <div className='d-flex justify-content-between align-items-center '>
                  <FaTelegram className='icon text-warning ' />
                  <p className='top_text'>&nbsp; Join on Telegram</p>
                  <IoIosArrowForward className='icon' />
                </div>
           
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
