/** @format */

import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
const Grid = () => {
  return (
    <>
      <div className='grid_sec'>
        <div className='container'>
          <h3>HOC Guideline Tutorials...</h3>
          <div className='row mt-5'>
            <div className='col-md-6 mt-3'>
              <div className='video_main'>
                {/* <iframe
                  src=""
                  frameBorder={0}
                  allowFullScreen
                  wmode="opaque"
                  id="fitvid430182"
                  data-ready="true"
                  contentEditable="false"
                /> */}
                <img
                  src='assets/images/vid.png'
                  className='img-fluid w-100'
                  alt=''
                />
              </div>

              <p>Free Tools</p>
              <h4>– HOC News</h4>
            </div>
            <div className='col-md-6 mt-3'>
              <img
                src='assets/images/strate.jpg'
                className='img-fluid'
                alt=''
              />
            </div>
            <div className='col-md-6 mt-3'>
              <div className='video_main'>
                {/* <iframe
                  src=""
                  frameBorder={0}
                  allowFullScreen
                  wmode="opaque"
                  id="fitvid430182"
                  data-ready="true"
                  contentEditable="false"
                /> */}

                <img
                  src='assets/images/vid.png'
                  className='img-fluid w-100'
                  alt=''
                />
              </div>
              <p>Free Tools</p>
              <h4>– HOC News</h4>
            </div>
            <div className='col-md-6 mt-3'>
              <div className='video_main'>
                {/* <iframe
                  src=""
                  frameBorder={0}
                  allowFullScreen
                  wmode="opaque"
                  id="fitvid430182"
                  data-ready="true"
                  contentEditable="false"
                /> */}
                <img
                  src='assets/images/vid.png'
                  className='img-fluid w-100'
                  alt=''
                />
              </div>
              <p>Free Tools</p>
              <h4>– HOC News</h4>
            </div>
          </div>

          <div className='mt-5 text-center '>
            <h3>Stay Informed with Our Blog & Updates</h3>
            <img
              src='assets/images/lightfsd.png'
              className='img-fluid'
              alt=''
            />
          </div>
          <div className='text-center mt-5'>
            <button className='btn-style '>
              Our Blogs &nbsp;
              <BsArrowUpRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grid;
