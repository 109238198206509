/** @format */

import Toggle from "../Utils/Toggle";
import { BsArrowUpRight } from "react-icons/bs";

const Hero = () => {
  return (
    <>
      <div className=' position-absolute end-0 mx-5 my-3'>
        <Toggle />
      </div>
      <div className='hero_sec'>
        <div className='container'>
          <div className='top_img'>
            <img src='assets/images/logo-2.png' className='img-fluid' alt='' />
          </div>
          <div className='py-3'>
            <h1>
              Welcome to <span> Home Owners Club </span> (HOC) Discover a New
              Way to Invest in
              <span> Real Estate </span>
              Join the Home Owners Club and be part of the{" "}
              <span> real estate revolution.</span>
              &nbsp;We're reshaping the property market, making it accessible to
              everyone. <br />
              <br />
              <span>Watch the video below to learn more.</span>
            </h1>
          </div>
          <hr className='text-white w-75 m-auto mb-5' />

          <div className='row'>
            <div className='col-md-7 col-12 video m-auto'>
              <div className='video_main'>
                <iframe
                  allow='autoplay'
                  src='https://www.youtube.com/embed/9TaO-LCrtBQ?si=KnhhpIU1MFEJHGE5'
                  frameBorder={0}
                  allowFullScreen
                  wmode='opaque'
                  id='fitvid458108'
                  title='About HOC'
                  data-ready='true'
                  className='cf-sticky-video-active rounded-4'
                  contentEditable='false'
                />

                {/* <img
                  src='assets/images/vid.png'
                  className='img-fluid w-100  rounded-4'
                  alt=''
                /> */}
              </div>
            </div>
            <div className='col-md-5 d-flex flex-column justify-content-center'>
              <div className='card'>
                <h3>
                  <span> About HOC </span> <br />
                  Revolutionizing Real Estate Investment with Blockchain
                </h3>
                <h4 className='text-center'>Our Mission</h4>

                <p>
                  At Home Owners Club (HOC), we're driven by a profound mission
                  - to democratize real estate investment through the
                  transformative power of blockchain technology. Our platform is
                  a catalyst that connects a global community of small
                  investors, offering them an unprecedented opportunity to
                  collectively own and invest in properties.
                </p>
                <a
                  href='whitepaper.pdf'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {" "}
                  <button className='btn-style '>
                    {" "}
                    <strong>Download Whitepaper</strong>
                  </button>
                </a>
                {/* <div className='text-center'>
                  <a
                    href='/whitepaper.pdf'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <button className='btn-style '>
                      <FaBook />
                      &nbsp; Whitepaper
                    </button>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className='hero_btn text-center'>
            <div>
              <h3 className='top_text text-center'>The Vision</h3>
              <span>
                {" "}
                Our vision is to create a world where real estate investment is
                not confined to the elite few but becomes accessible to
                everyone, regardless of their financial status. We believe that
                by fostering a sense of collective ownership, we can reshape the
                entire landscape of property investment, making it more
                inclusive and equitable.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
