/** @format */

import React from "react";
import { IoIosArrowForward } from "react-icons/io";

const Guarantee = () => {
  return (
    <>
      <div className='guarantee_sec'>
        <div className='container text-center'>
          <h3>
            Keep yourself in the loop with the latest developments in real
            estate and blockchain technology.
          </h3>
          <p>Reach Out to Us</p>
          <p>
            Need tailored assistance? Our team is just a message away, ready to
            address your inquiries.
          </p>
        
          <div className='image'>
            {/* <img src='assets/images/mobile.png' className='img-fluid' alt='' /> */}
          </div>
          <div className='hero_btn'>
            <button>
              <div className='d-flex justify-content-between align-items-center '>
                <p className='top_text'>Contact Us</p>
                <IoIosArrowForward className='icon' />
              </div>
             
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guarantee;
